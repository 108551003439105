// eslint-disable-next-line import/prefer-default-export
export const hbOverviewHero = {
  title: "Elegant software for you and your clients",
  subtext:
    "Streamline your business with a custom platform designed to attract more clients and keep them coming back.",
  heroImg: "homepage-health-and-beauty.png",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/demo",
  },
};

export const hbOverviewTrusted = [
  {
    title: "30 – 40%",
    subtext: "Increase in sales with online booking",
  },
  {
    title: "40%",
    subtext: "Decrease in no-shows with automated reminders",
  },
  {
    title: "31%",
    subtext: "Increase in sales with repeat clients",
  },
  {
    title: "25 – 95%",
    subtext: "Profit growth with a 5% increase in client retention",
  },
];

export const hbOverviewWhiteGlove = {
  mainTitle: "We are there for you & your customers",
  title: "Smart technology backed by real people",
  content:
    "Your business isn’t like other small businesses, so don’t box yourself in with a one-size-fits-all solution. The SpotOn team will work one-on-one with you to assess your needs and build an elegant solution to help you book more appointments, manage staff schedules, take payments, and improve client retention, while minding capacity restrictions and contact tracing requirements.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/demo",
  },
};
export const hbOverviewSubverticals = {
  title: "Technology designed for health & beauty",
  subverticalInfo: [
    {
      target: "/health-and-beauty/hair-salons",
      iconImg: "hair-salon.png",
      subverticalName: "Hair salons",
      subverticalContent:
        "Whether you have employees, rent chairs, or both, we’ll revitalize your salon with worry-free technology.",
    },
    {
      target: "/health-and-beauty/nail-salons",
      iconImg: "nail-salon.png",
      subverticalName: "Nail salons",
      subverticalContent:
        "Infuse your nail salon with the right tools to book more clients, save time, and get paid quickly.",
    },
  ],
};

export const hbOverviewMultiSection = {
  sectionTitle: "Technology to connect with your clients",
  featureBlocks: [
    {
      blockTitle: "Make booking appointments easy",
      blockSubTitle:
        "Increase sales by 30 to 40% with online booking, reduce no-shows, and save time with intuitive tools to streamline staff scheduling, the checkout process, and COVID-19 requirements.",
      blockImg: "hb-a.png",
      blockList: [
        "Online booking",
        "Interactive calendaring",
        "No-show protection",
        "Contact tracing",
        "Built-in credit card processing",
      ],
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/demo",
      },
    },
    {
      blockTitle: "Improve client retention",
      blockSubTitle:
        "Deliver the kind of experience that turns casual customers into regulars by rewarding your clients. Create custom loyalty rewards and watch revenue grow as clients enroll, earn points, and redeem rewards during checkout.",
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/demo",
      },
      blockImg: "hb-b.png",
      forceReverse: false,
    },
    {
      blockTitle: "Attract new clients with a new website",
      blockSubTitle:
        "It takes 40 hours to design your own website. Our team will build you a stunning site at a do-it-yourself price in 48 hours, with integrated appointment booking to make it easy for clients to schedule their appointment online, even after hours.",
      blockList: [
        "Custom website design",
        "Online booking",
        "Web hosting",
        "Personalized web address",
        "Self-service dashboard",
        "Lifetime support",
      ],
      blockImg: "hb-c.png",
      forceReverse: false,
      ctaInfoPrimary: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/demo",
      },
    },
    {
      blockTitle: "Create marketing campaigns like a pro",
      blockSubTitle:
        "Deepen your client relationships with easy-to-use tools for sending branded marketing campaigns to every client who has booked an appointment or enrolled in your loyalty program.",
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/demo",
      },
      blockList: [
        "Email marketing",
        "Limited-time deals",
        "Facebook campaigns",
        "Customizable branding",
      ],
      blockImg: "hb-d.png",
      forceReverse: false,
    },
    {
      blockTitle: "Manage your online reputation",
      blockSubTitle:
        "Know what people say about you on popular review sites like Yelp, TripAdvisor, Google, and Facebook. Our online review management software makes it easy to monitor all of your online reviews in one place and get automated alerts when new reviews are posted—so that you can not only monitor your online reputation, but improve it.",
      blockImg: "hb-e.png",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/demo",
      },
    },
  ],
};
export const hbOverviewPartIntegration = {
  title: "Integrated point-of-sale and payment terminal options",
  integrationsContent: [
    {
      title: "SpotOn Terminal",
      subtext:
        "Get personal with the all-in-one smart device for payments, marketing, and customer insights.",
      contentImg: "terminal.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/terminal",
      },
    },
    {
      title: "SpotOn Register",
      subtext:
        "Get all the power of a full point-of-sale system in a compact, easy-to-use design.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/register",
      },
    },
  ],
};

export const hbOverviewCTA = {
  title: "Sign up for your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/demo",
  },
};

export const hbOverviewVideo = {
  title: "Used by pros",
  videoBtn: "Watch video",
  posterImg: "terminal-poster.png",
  videoUrlId: "zWmPXc48JW4",
};

export const hbOverviewTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const hbOverviewArticles = {
  title: "Expert advice on growing your business",
  bgText: "Articles.",
};

export const hbByTheNumbers = {
  title: "By the numbers",
  pie: {
    title: "Grow",
    subTitle: "with the right tools",
  },

  leftFacts: [
    {
      title: "40%",
      subTitle: "decrease in no-shows with automated reminders",
    },
    {
      title: "31% ",
      subTitle: "increase in sales with repeat customers",
    },
  ],

  rightFacts: [
    {
      title: "88%",
      subTitle:
        "customers trust online reviews as much as personal recommendations",
    },
    {
      title: "30 – 40%",
      subTitle: "increase in sales with online booking",
    },
  ],
};

export const hbOverviewFaqs = {
  title: "Frequently Asked Questions.",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};
