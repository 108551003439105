import React from "react";
import loadable from "@loadable/component";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import {
  hbOverviewHero,
  hbOverviewWhiteGlove,
  hbOverviewMultiSection,
  hbOverviewCTA,
  hbOverviewVideo,
  hbOverviewTestimonials,
  hbOverviewArticles,
  // hbOverviewFaqs,
  hbOverviewSubverticals,
  hbOverviewPartIntegration,
  hbOverviewTrusted,
} from "../data/beauty-overview-data";
import { hbBTSlides, heroSuccess } from "../data/success-stories-data";
import Hero from "../components/Hero/hero";
// import WhiteGlove from "../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../components/LargeFeatures/LargeFeatures";
// import LargeCta from "../components/CTAs/LargeCta";
// import SuccessStories from "../components/SuccessStories/SuccessStories";
// import VideoSection from "../components/VideoSection/VideoSection";
// import TestmonialReviews from "../components/TestimonialReviews/TestmonialReviews";
// import Subverticals from "../components/SubVerticals/Subverticals";
// import TrustedNumbers from "../components/TrustedNumbers/TrustedNumbers";
// import IntegrationsSection from "../components/Integrations/IntegrationsSection";
// import Articles from "../components/Articles/Articles";

import ogImage from "../images/global_assets/og-image.png";

const WhiteGlove = loadable(() =>
  import("../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../components/LargeFeatures/LargeFeatures")
);
const LargeCta = loadable(() => import("../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../components/VideoSection/VideoSection")
);
const TestmonialReviews = loadable(() =>
  import("../components/TestimonialReviews/TestmonialReviews")
);
const Subverticals = loadable(() =>
  import("../components/SubVerticals/Subverticals")
);
const TrustedNumbers = loadable(() =>
  import("../components/TrustedNumbers/TrustedNumbers")
);
const IntegrationsSection = loadable(() =>
  import("../components/Integrations/IntegrationsSection")
);
const Articles = loadable(() => import("../components/Articles/Articles"));

const whiteGloveImport = "bh-white-glove.png";

const HealthAndBeauty = () => {
  return (
    <Layout>
      <SEO
        title="SpotOn Health &amp; Beauty | Appointment Booking | Point-of-Sale"
        description="Your time is precious and should be spent taking care of your clients, not getting bogged down in tedious back-end tasks. We’re here to help with an elegant 
          toolset fashioned to save you time, grow revenue, and keep things humming along. Make it easy for your clients to book appointments online. Improve client retention. 
          And take advantage of new ways to get paid. SpotOn will set you up with everything you need, backed by personalized service and support."
        image={`https://spoton.com/${ogImage}`}
      />
      <Hero sectionData={hbOverviewHero} circleBg={false} />
      <TrustedNumbers numbersArray={hbOverviewTrusted} />
      <WhiteGlove
        sectionData={hbOverviewWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <Subverticals sectionData={hbOverviewSubverticals} />
      <LargeFeatures
        sectionData={hbOverviewMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <VideoSection sectionData={hbOverviewVideo} />
      <SuccessStories sectionData={heroSuccess} slides={hbBTSlides} />
      <IntegrationsSection sectionData={hbOverviewPartIntegration} />
      <Articles
        sectionData={hbOverviewArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:small-business",
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      />
      <LargeCta sectionData={hbOverviewCTA} />
      <TestmonialReviews sectionData={hbOverviewTestimonials} />
      {/* <PartnerLogos />
      <ByTheNumbers sectionData={hbByTheNumbers} /> */}
      {/* <Faqs sectionData={hbOverviewFaqs} /> */}
      {/*      <Articles
        sectionData={hbOverviewArticles}
        blogEntries={tempData}
        cornerStone={false}
      /> */}
    </Layout>
  );
};

export default HealthAndBeauty;
